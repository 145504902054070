import Avatar from "react-avatar"

const Profile = ({ data, onClick }) => (
    <div className="container absolute w-64 top-15 right-10 rounded-lg bg-black bg-opacity-80 text-white font-sans z-[2]">
        <div className="flex flex-col pb-7">
            <Avatar name={data.full_name} color="#fff" fgColor="#000" size="80" round="50px" className="mt-3 ml-11 mb-6" />
            <div className="text-center">
                <span className="text-md">{data.full_name}</span>
            </div>
            <div className="text-center mb-4">
                <span className="text-xs">{data.email_address}</span>
            </div>
            <hr className="border-2 mx-7"></hr>
            <div className="pl-7 mb-1 mt-4">
                <span className="text-xs">Position Apply : <span className="bg-white text-black font-bold px-1 rounded-lg">{data.applied_position}</span></span>
            </div>
            <div className="pl-7 mb-1 mt-1">
                <span className="text-xs">Last Education : <span className="bg-white text-black font-bold px-1 rounded-lg">{data.education}</span></span>
            </div>
            <div className="pl-7 mb-1 mt-1">
                <span className="text-xs">Gender : <span className="bg-white text-black font-bold px-1 rounded-lg">{data.gender === "L" ? "Laki Laki" : "Perempuan"}</span></span>
            </div>
            <div className="pl-7 mb-1 mt-1">
                <span className="text-xs">Phone Number : <span className="bg-white text-black font-bold px-1 rounded-lg">+{data.dial_code}{data.phone_number}</span></span>
            </div>
            <div className="text-center mt-4">
                <button onClick={onClick()} className="text-xs bg-blue-600 py-1 px-4 rounded-xl">Logout</button>
            </div>
        </div>
    </div>
)

export default Profile;