import React from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';
import { useState } from "react";
import { useEffect } from "react";

import ExamService from "services/exam.service";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/auth";
import { Loading } from "components";
import { useOnPrintScreen } from "context/useOnKeyPress";

const ModuleIntro = () => {

    const params = useParams();
    const [intro, setIntro] = useState({});
    const [modulsoal, setModulSoal] = useState(0);
    const [loading, setLoading] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useOnPrintScreen();

    useEffect(() => {
        setLoading(true);
        ExamService.detailModuleSoal(params.id).then(
            (response) => {
                const { data } = response.data;
                setIntro(data.intro);
                setModulSoal(data.id);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);

                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                }
            }
        )
            .finally(() => {
                setLoading(false);
            })
    }, [params]);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {loading && <Loading />}
            <div id="contentHolder">
                <div id="testDescription">
                    {intro && ReactHtmlParser(intro.toString())}
                    <div className="text-center mt-5">When you are ready, click "Begin" to start the test.</div>
                    <Link
                        to={{
                            pathname: `/question/${modulsoal}`,
                            state: { id: modulsoal }
                        }}
                    >
                        <div className="normalButton normalGreenButton centerMe mt-2" id="beginButtonToRemove">Begin</div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ModuleIntro;