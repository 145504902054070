import axios from "config/axiosConfig";

const getBankSoal = () => {
  return axios.get("/exam/banksoal");
};

const detailBankSoal = (id) => {
  return axios.get("/exam/banksoal/" + id);
};

const detailModuleSoal = (id) => {
  return axios.get("/exam/modulsoal/" + id);
};

const getQuestions = (id) => {
  return axios.get("/exam/questions/" + id);
};

const saveCandidateAnswer = (id, data) => {
  return axios.post("/exam/candidate-answer/" + id, data);
};

const saveTempCandidateAnswer = (id, data) => {
  return axios.post("/exam/temp-candidate-answer/" + id, data);
};

export default {
  getBankSoal,
  detailBankSoal,
  detailModuleSoal,
  getQuestions,
  saveCandidateAnswer,
  saveTempCandidateAnswer,
};
