import { useEffect } from "react";

const listener = (event) => {
    let keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode == 44) {
        stopPrintScreen();
        alert("Print Screen is not allowed!");
    }
};

export function useOnPrintScreen() {
    useEffect(
        () => {
            document.addEventListener("keyup", listener);
            return () => {
                document.removeEventListener("keyup", listener);
            }
        },
        [listener]
    )
}

const stopPrintScreen = () => {
    let inpFld = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove(inpFld);
}