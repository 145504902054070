import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import LoginImage from "assets/images/login illustration.png";
import LogoSoechi from "assets/images/logo soechi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "redux/actions/auth";

const required = (value) => {
    if (!value) {
        return (
            <div className="ml-8 text-left text-sm text-red-600" role="alert">
                This field is required!
            </div>
        );
    }
};

const Login = (props) => {
    let navigate = useNavigate();

    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const eye = <FontAwesomeIcon icon={faEye} />;

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(login(username, password))
                .then(() => {
                    navigate("/landing");
                    window.location.reload();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }

    if (isLoggedIn) {
        return <Navigate to="/landing" />;
    }

    return (
        <div className="flex flex-row justify-center h-screen">
            <div className="m-auto w-[500px] pl-[150px]">
                <div className="flex flex-col text-black text-2xl font-bold font-sans">
                    Welcome
                </div>
                <div className="flex flex-row">
                    <h1 className="text-[#285395] font-sans font-bold text-3xl">
                        SOECHI
                    </h1>
                    <h2 className="font-bold font-mono text-2xl text-white ml-3 mt-1">
                        Online Assesment
                    </h2>
                </div>
                <img src={LoginImage} className="max-w-sm" />
            </div>
            <div className="basis-1/3 m-auto pr-10">
                <div className="bg-white rounded-2xl pt-14 pb-10 text-center w-[350px] h-[400px]">
                    <Form onSubmit={handleLogin} ref={form}>
                        <img src={LogoSoechi} className="mx-auto pb-5" />
                        <Input
                            type="text"
                            className="font-sans border-[##828282] border-solid rounded-lg border pt-1 pb-1 pl-3 mb-2 text-sm w-10/12"
                            placeholder="Username"
                            name="username"
                            onChange={onChangeUsername}
                            value={username}
                            validations={[required]}
                        />
                        <Input
                            type={isPasswordVisible ? "text" : "password"}
                            className="font-sans border-[##828282] border-solid rounded-lg border pt-1 pb-1 pl-3 mt-2 mb-1 text-sm w-10/12"
                            placeholder="Password"
                            name="password"
                            onChange={onChangePassword}
                            value={password}
                            validations={[required]}
                        />
                        <span
                            className="absolute flex items-center px-4 text-gray-600 ml-[275px] mt-[-28px]"
                            onClick={togglePasswordVisibility}
                        >
                            {isPasswordVisible ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            )}
                        </span>

                        {/* <i onClick={togglePasswordVisiblity} className="absolute right-20">{eye}</i> */}
                        <button className="cursor-pointer rounded-lg font-sans text-white bg-[#285395] pt-1 pb-2 pl-3 mt-5 w-10/12">
                            {loading ? (
                                <div
                                    role="status"
                                    className="flex flex-col items-center pt-1 pb-1"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="text-center w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                </div>
                            ) : (
                                <span>Login</span>
                            )}
                        </button>
                        {message && (
                            <div className="mt-2 text-sm text-red-600" role="alert">
                                {message}
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
