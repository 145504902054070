import { Link } from "react-router-dom";
import ExamIllustration1 from 'assets/images/exam illustration 1.png';
import ExamIllustration2 from 'assets/images/exam illustration 2.png';
import ExamIllustration3 from 'assets/images/exam illustration 3.png';

const ExamImage = [
    ExamIllustration1,
    ExamIllustration2,
    ExamIllustration3
]

const SwiperItem = ({ data, idx }) => (
    <div className="flex items-center justify-between">
        <div className="basis-1/2">
            <div className="pl-20">
                <h1 className="text-[#285395] font-bold font-sans text-2xl">{data.banksoal_name}</h1>
                <div className="text-black text-sm font-sans w-1/2 h-20 mt-2 mb-2">
                    {/* {data.banksoal_name} Test ini ingin mengetahui kemampuan peserta dalam test ini. */}
                </div>
                <Link
                    to={{
                        pathname: `/intro/${data.id}`,
                        state: { id: data.id }
                    }}
                    className="inline-block font-sans text-sm px-6 pb-1 text-white font-bold bg-black rounded-lg">
                    Start
                </Link>
            </div>
        </div>
        <div className="basis-1/2">
            <img src={ExamImage[idx]} className="w-1/2" />
        </div>
    </div>
)

export default SwiperItem