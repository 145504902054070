import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Profile = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    if(!currentUser) {
        return <Navigate to="/" />;
    }
}

export default Profile;