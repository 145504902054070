import axios from "config/axiosConfig";

const login = (username, password) => {
  return axios
    .post("/auth/login", {
      username,
      password,
    })
    .then((response) => {
      const { data } = response.data;
      if (data.access_token) {
        localStorage.setItem("user", JSON.stringify(data));
      }

      return response.data;
    });
};

const profile = () => {
  return axios.get("/auth/profile");
};

const logout = () => {
  return axios.post("/auth/logout");
};

export default {
  login,
  profile,
  logout,
};
