import React from "react";

const Finish = () => {
    return (
        <div id="contentHolder">
            <div id="testDescription">
                <div className="text-center mb-10">
                    <h1 className="text-2xl">PENUTUP</h1>
                </div>
                Anda saat ini telah selesai mengerjakan seluruh materi test yang tersedia. Terima kasih banyak atas partisipasi anda dalam mengikuti rangkaian seleksi dari Soechi Group, Pihak HR akan menghubungi anda apabila anda lolos dari tahapan aptitude test. Apabila terdapat pertanyaan lebih lanjut silahkan berkorespondensi melalui email ke alamat <a href="mailto: recruitment.office@soechi.id" className="underline text-blue-600">recruitment.office@soechi.id</a>.
            </div>
        </div>
    )
}

export default Finish