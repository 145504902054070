import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as pages from 'pages';

const AppRouter = () => (
    <Routes>
        <Route path="/" element={<pages.Login />} />
        <Route path='/intro/:id' element={<pages.Intro />} />
        <Route path='/module-intro/:id' element={<pages.ModuleIntro />} />
        <Route path='/question/:id' element={<pages.Question />} />
        <Route path='/finish' element={<pages.Finish />} />
        <Route path='/login' element={<pages.Login />} />
        <Route path='/profile' element={<pages.Profile />} />
        <Route path='/landing' element={<pages.Landing />} />
    </Routes>
)

export default AppRouter;