import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LogoSoechi from "assets/images/logo soechi.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import { authService, examService } from "services/index";
import { useOnHoverOutside } from "context/useOnHoverOutside";
import { useOnPrintScreen } from "context/useOnKeyPress";
import { Profile, SwiperItem, Loading } from "components/index";
import { getTotalMinutes } from "helpers/duration";
import { userIcon, stopwatchIcon, clipboardIcon } from "constants/icon";

const Landing = () => {
  let navigate = useNavigate();
  const profileRef = useRef(null);
  const [content, setContent] = useState([]);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [isMenuProfileOpen, setIsMenuProfileOpen] = useState(false);
  const [swiper, setSwiper] = useState(Swiper);
  const [swiperIndex, setSwiperIndex] = useState(0);

  // Function to close dropdown
  const closeHoverMenu = () => {
    setIsMenuProfileOpen(false);
  };

  const handleLogout = async () => {
    try {
      const res = await authService.logout();
      if (res) {
        localStorage.removeItem("user");
        navigate("/");
        setLoading(false);
      }
    } catch (error) {
      console.error(`error logout ${error.message}`);
    }
  };

  const fetchBankSoal = async () => {
    try {
      const res = await examService.getBankSoal();
      const { data } = res.data;
      if (data) {
        setContent(data);
        if (data.length === 0) {
          navigate("/finish");
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(`error fetching bank soal ${error.message}`);
    }
  };

  const fetchProfile = async () => {
    try {
      const res = await authService.profile();
      const { data } = res.data;
      if (data) {
        const { candidates } = data;
        setProfile(candidates);
      }
    } catch (error) {
      console.log(`error fetching profile data ${error.message}`);
    }
  };

  const toggleProfile = () => {
    setIsMenuProfileOpen((prevstate) => !prevstate);
  };

  useOnHoverOutside(profileRef, closeHoverMenu);
  useOnPrintScreen();

  useEffect(() => {
    setLoading(true);
    fetchBankSoal();
    fetchProfile();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <nav className="flex items-center justify-between flex-wrap bg-white pt-2 pb-2 pl-10">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <img src={LogoSoechi} className="w-1/4" />
          <h2 className="text-[#285395] text-lg font-sans font-bold">
            PT Soechi Lines Tbk
          </h2>
        </div>
        <div className="w-full block lg:flex lg:items-center lg:w-auto">
          <div className="rounded-3xl bg-[#dfe3ef] items-end float-right mr-10">
            <a
              onClick={toggleProfile}
              href="#"
              className="inline-block font-sans text-sm px-4 py-2 text-black font-bold"
            >
              {profile && profile.full_name} <i className="ml-1">{userIcon}</i>
            </a>
          </div>
        </div>
      </nav>
      {isMenuProfileOpen && (
        <Profile onClick={() => handleLogout} data={profile} />
      )}
      <div className="container mx-auto">
        <Swiper
          initialSlide={swiperIndex}
          slidesPerView={1}
          spaceBetween={30}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
        >
          {content &&
            content.map((x, i) => (
              <SwiperSlide key={i}>
                <SwiperItem data={x} idx={i} />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="rounded-full h-80 w-80 bg-[#285395] absolute mt-[-10px] ml-[-80px] z-[-1]"></div>
        <div className="basis-full mt-5">
          <div className="flex flex-row pl-14 pr-14">
            {content &&
              content.map((x, i) => (
                <div
                  onClick={() => swiper.slideTo(i)}
                  key={i}
                  className={`md:w-[350px] pt-4 pb-4 rounded-lg bg-[#BDBDBD] text-center mb-5 mr-10 cursor-pointer ${swiperIndex === i ? "shadow-[0_10px_20px_#285395]" : ""
                    }`}
                >
                  <h1 className="text-1xl font-bold text-white font-sans mb-3">
                    {x.banksoal_name}
                  </h1>
                  <div className="text-black px-5 lg:h-[100px]">
                    {/* {x.banksoal_name} Test ini ingin mengetahui kemampuan peserta dalam test ini. */}
                  </div>
                  <div className="flex flex-row mt-2 justify-center">
                    <i className="mr-2">{stopwatchIcon}</i>
                    <span className="text-[#285395]">
                      {getTotalMinutes(x)} Minutes
                    </span>
                    <i className="mr-2 ml-5">{clipboardIcon}</i>
                    <span className="text-[#285395]">
                      {x.total_soal} Questions
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
