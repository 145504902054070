import axios from "axios";
import { EXAM_API_URL } from "constants/common";

const checktoken = () => {
  const token = JSON.parse(localStorage.getItem("user"));
  if (token) {
    const { access_token } = token;
    return { Authorization: "Bearer " + access_token };
  } else {
    return {};
  }
};

const instance = axios.create({
  baseURL: EXAM_API_URL,
  headers: checktoken(),
  timeout: 10000, // 10 second
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location = "/login";
    }
  }
);

export default instance;
