export function getTotalMinutes(x) {
    let total = 0;
    let array = x.data;

    if (array.length) {
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            total += element.duration;
        }
    }

    return Math.floor(total / 60);
}